.tooltipBubble {
    min-width: auto;
    max-width: 210px;
    position: absolute;
    z-index: 10;
}
.tooltipBubble::after {
    content: "";
    position: absolute;
}
.tooltipTop {
    bottom: 100%;
    left: 50%;
    padding-bottom: 8px;
    transform: translateX( -50% );
}
.tooltipTop::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #0A0B0D;
    bottom: 0;
    left: 50%;
    transform: translateX( -50% );
}
.tooltipBottom {
    top: 100%;
    left: 50%;
    padding-top: 8px;
    transform: translateX( -50% );
}
.tooltipBottom::after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #0A0B0D;
    top: 0;
    left: 50%;
    transform: translateX( -50% );
}
.tooltipLeft {
    top: 50%;
    right: 100%;
    padding-right: 8px;
    transform: translateY( -50% );
}
.tooltipLeft::after {
    border-left: 8px solid #0A0B0D;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    top: 50%;
    right: 0;
    transform: translateY( -50% );
}
.tooltipRight {
    top: 50%;
    left: 100%;
    padding-left: 8px;
    transform: translateY( -50% );
}
.tooltipRight::after {
    border-right: 8px solid #0A0B0D;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY( -50% );
}