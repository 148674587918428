.table-container {
    height: 200px;
    /* Adjust the height as needed */
    overflow-y: scroll;
}

.table-fixed-header thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
}